import {
  Button,
  Heading,
  VStack,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  SimpleGrid,
  Grid,
} from "@chakra-ui/react";
import { useState } from "react";
import { dbGiveOffer, dbDeclineMatch } from "../databasefunctions/matches";
import OneColumnLayout from "../layout/OneColLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { Bid } from "../layout/BidDialogCarrier";

import { BrowserView, MobileView } from "react-device-detect";

interface CargoMatchState {
  distance_diff: number;
  distance_package: number;
  duration_diff: number;
  duration_package: number;
  package_height: number;
  package_length: number;
  package_width: number;
  package_weight: number;
  pick_up_point: string;
  drop_off_point: string;
  earliest_start_time: string;
  latest_start_time: string;
  earliest_end_time: string;
  latest_end_time: string;
  load_types: string[];
  incoterms: { code: string; discription: string };
  tracking_number: string;
  car_reg_number: string;
  note: string;
  noteCargo: string;
  loading_requirements: string[];
}
/**********************************************************
/ NAME: GiveOfferView()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a view for giving an offer. 
 * It displays information about the cargo and allows the user to give an offer.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * useLocation() - Returns the current location object which contains information about the current URL
 * useNavigate() - Returns a function that navigates to different pages
 * dbGiveOffer() - Sends an offer to the database
 * dbDeclineMatch() - Declines a match in the database
 * 
/********************************************************** 
 * COMPONENTS:
 * - useState: A React hook that lets you add state to function components
 * - Button: A component that renders a button
 * - Heading: A component that renders a heading
 * - VStack: A container that stacks its children vertically
 * - Text: A component that renders text
 * - Input: A component that renders an input field
 * - Table: A component that renders a table
 * - Thead: A component that renders a table head
 * - Tbody: A component that renders a table body
 * - Tr: A component that renders a table row
 * - Th: A component that renders a table header
 * - Td: A component that renders a table data cell
 * - Box: A component that renders a box
 * - TableContainer: A component that renders a table container
 * - SimpleGrid: A component that renders a simple grid
 * - Grid: A component that renders a grid
 * - OneColumnLayout: A component that renders a one column layout
 * - Bid: A component that renders a bid dialog
 * - BrowserView: A component that renders its children only on desktop devices
 * - MobileView: A component that renders its children only on mobile devices
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing information about the cargo and a form for giving an offer.
/*********************************************************/
function GiveOfferView() {
  const { state } = useLocation();
  const {
    distance_diff,
    distance_package,
    duration_package,
    duration_diff,
    package_height,
    package_length,
    package_width,
    package_weight,
    pick_up_point,
    drop_off_point,
    earliest_start_time,
    latest_start_time,
    earliest_end_time,
    latest_end_time,
    load_types,
    incoterms,
    tracking_number,
    car_reg_number,
    note,
    noteCargo,
    loading_requirements,
  } = state as CargoMatchState;
  const [distanceDiff] = useState(distance_diff);
  const [distancePackage] = useState(distance_package);
  const [durationPackage] = useState(duration_package);
  const [durationDiff] = useState(duration_diff);
  const [packageHeight] = useState(package_height);
  const [packageLength] = useState(package_length);
  const [packageWidth] = useState(package_width);
  const [packageWeight] = useState(package_weight);
  const [pickUpPoint] = useState(pick_up_point);
  const [earliestStartTime] = useState(earliest_start_time);
  const [latestStartTime] = useState(latest_start_time);
  const [earliestEndTime] = useState(earliest_end_time);
  const [latestEndTime] = useState(latest_end_time);
  const [dropOffPoint] = useState(drop_off_point);
  const [loadTypes] = useState(load_types);
  const [incoTerms] = useState(incoterms);
  const [trackingNumber] = useState(tracking_number);
  const [regNumber] = useState(car_reg_number);
  const [offerValue, setOfferValue] = useState("");
  const [isShown, setIsShown] = useState(false);
  const navigate = useNavigate();

  async function sendOffer() {
    await dbGiveOffer(trackingNumber, regNumber, offerValue).then(() => {
      navigate("/trips");
    });
  }
  const declineMatch = async () => {
    await dbDeclineMatch(trackingNumber, regNumber).then(() => {
      navigate("/trips");
    });
  };
  //console.log(incoTerms)
  //console.log(loadTypes)
  return (
    <OneColumnLayout
      sectionLabel="Give Offer"
      sectionHeading="Match found"
      sectionIntro=""
      content={
        <form>
          <BrowserView>
            <Heading ml="5" size={"md"}>
              Evaluate the particulars of the match and give offer
            </Heading>
            <TableContainer width="85vw">
              <Table
                variant="striped"
                width={"80vw"}
                maxWidth="800px"
                wordBreak="break-all"
              >
                <Thead>
                  <Tr>
                    <Th>Parameter</Th>
                    <Th>Verdi</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Hentested</Td>
                    <Td>{pickUpPoint}</Td>
                  </Tr>
                  <Tr>
                    <Td>Hentetid</Td>
                    <Td>
                      {earliestStartTime} - {latestStartTime}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Leveringssted</Td>
                    <Td>{dropOffPoint}</Td>
                  </Tr>
                  <Tr>
                    <Td>Leveringstid</Td>
                    <Td>
                      {earliestEndTime}- {latestEndTime}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Tilleggsavstand [km]</Td>
                    <Td>{distanceDiff / 1000}</Td>
                  </Tr>
                  <Tr>
                    <Td>Avstand med gods [km]</Td>
                    <Td>{Math.round(distancePackage / 1000)}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tilleggsvarighet [min]</Td>
                    <Td>{durationDiff}</Td>
                  </Tr>
                  <Tr>
                    <Td>Varighet med gods [min]</Td>
                    <Td>{durationPackage}</Td>
                  </Tr>
                  <Tr>
                    <Td>Godsdimensjoner [cm]x[cm]x[cm]</Td>
                    <Td>
                      {packageLength}x{packageHeight}x{packageWidth}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Godsvekt [kg]</Td>
                    <Td>{packageWeight}</Td>
                  </Tr>
                  <Tr>
                    <Td>Godstype</Td>
                    <Td>{loadTypes}</Td>
                  </Tr>
                  <Tr>
                    <Td>Lastebilen må ha med seg følgende laste/losseutstyr</Td>
                    <Td>{loading_requirements}</Td>
                  </Tr>
                  <Tr>
                    <Td>Incoterms</Td>
                    <Td
                      onMouseEnter={() => setIsShown(true)}
                      onMouseLeave={() => setIsShown(false)}
                    >
                      {incoTerms.code}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Andre merknader Transportkjøper</Td>
                    <Td>{noteCargo}</Td>
                  </Tr>
                  <Tr>
                    <Td>Andre merknader Transportør</Td>
                    <Td>{note}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            {isShown && (
              <Box width={"80vw"} maxWidth="800px">
                <Text>{incoTerms.discription}</Text>{" "}
              </Box>
            )}
          </BrowserView>
          <MobileView>
            <Box>
              <SimpleGrid columns={2} columnGap="0" rowGap={2}>
                <Box bg="subtle">
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Hentested
                  </Text>
                </Box>
                <Box bg="subtle">{pickUpPoint}</Box>
                <Box>
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Hentetid
                  </Text>
                </Box>
                <Box>
                  {earliestStartTime} - {latestStartTime}
                </Box>
                <Box bg="subtle">
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Leveringssted
                  </Text>
                </Box>
                <Box bg="subtle">{dropOffPoint}</Box>
                <Box>
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Leveringstid
                  </Text>
                </Box>
                <Box>
                  {earliestEndTime}- {latestEndTime}
                </Box>
                <Box bg="subtle">
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Tilleggsavstand [km]
                  </Text>
                </Box>
                <Box bg="subtle">{distanceDiff / 1000}</Box>
                <Box>
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Avstand med kolli[km]
                  </Text>
                </Box>
                <Box>{Math.round(distancePackage / 1000)}</Box>
                <Box bg="subtle">
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Tilleggsvarighet [min]
                  </Text>
                </Box>
                <Box bg="subtle">{durationDiff}</Box>
                <Box>
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Varighet med kolli
                  </Text>
                </Box>
                <Box>{durationPackage}</Box>
                <Box bg="subtle">
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Dimensjoner [cm]
                  </Text>
                </Box>
                <Box bg="subtle">
                  {packageLength}x{packageHeight}x{packageWidth}
                </Box>
                <Box>
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Kollivekt[kg]
                  </Text>
                </Box>
                <Box>{packageWeight}</Box>
                <Box bg="subtle">
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Godstype
                  </Text>
                </Box>
                <Box bg="subtle">{loadTypes}</Box>
                <Box>
                  <Text ml="2" mb="0" mt="0" variant="label">
                    Incoterms
                  </Text>
                </Box>
                <Box
                  onMouseEnter={() => setIsShown(true)}
                  onMouseLeave={() => setIsShown(false)}
                >
                  {incoTerms.code}
                </Box>
              </SimpleGrid>
            </Box>
          </MobileView>
          <VStack align={"normal"}>
            <Text mt="2" mb="-2" variant="label" as="b">
              Ønsker du å gi et bud på denne jobben?
            </Text>
            <Input
              variant="standardTextInput"
              value={offerValue}
              placeholder="How Much are you willing to offer?"
              onChange={(e) => setOfferValue(e.target.value)}
              margin="10px"
              maxWidth="800px"
            />
            <Grid maxWidth="800px" templateColumns="50% 50%" gap={2}>
              <Button colorScheme={"red"} mt="10px" onClick={declineMatch}>
                Avslå
              </Button>
              <Bid
                onConfirm={() => sendOffer()}
                price={offerValue}
                pickUpPoint={pickUpPoint}
                pickUpTime={earliestStartTime + "-" + latestStartTime}
                incoTerms={incoTerms.code}
                dropOffPoint={dropOffPoint}
                dropOffTime={earliestEndTime + "-" + latestEndTime}
                type={loadTypes}
                size={packageLength + "x" + packageWidth + "x" + packageHeight}
                weight={packageWeight}
                distance={Math.round(distancePackage / 1000)}
                time={Math.round(durationPackage / 60)}
                emissions="0"
                noteCargoOwner={noteCargo}
                noteTrip={note}
                loadingRequirement={loading_requirements}
                trackingNumber={trackingNumber}
                carRegNumber={car_reg_number}
              />
            </Grid>
          </VStack>
        </form>
      }
    />
  );
}

export default GiveOfferView;
