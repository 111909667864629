/**********************************************************
/ NAME: DealSigned()
/********************************************************** 
 * SUMMARY: 
 * This component is rendered when a cargo match is signed. It displays a message indicating the necessary information
 * will be sent to the Cargo Owner from the Carrier.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props. It uses the useLocation hook to get the state passed from the previous route.
 * 

/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a message about the payment status.
/*********************************************************/
// Importing necessary libraries and components
import { Button, Text, VStack } from "@chakra-ui/react";
import OneColumnLayout from "../layout/OneColLayout";

// PaymentStatus component
function DealSigned() {
  
  // Render the component
  return (
    <OneColumnLayout
      sectionLabel="Dashboard"
      sectionHeading="Avtale gjort og oppdrag klar for utførelse"
      sectionIntro=""
      content={
        <VStack>
          <Text fontSize="2xl">Avtale om utførelse av oppdraget er klar</Text>
          <Text>
            Du vil nå få fraktpapirer og evt annen info om utførelsen tilsendt på epost fra valgte transportør.
          </Text>
          <Button variant="return" as="a" href="/dashboard">
            Tilbake
          </Button>
        </VStack>
      }
    />
  );
}

// Exporting the component
export default DealSigned;
