import { Button, Heading, Text, Input, Grid } from "@chakra-ui/react";
import { useState } from "react";
import {
  dbGiveOffer,
  dbDeclineMatch,
  dbAcceptBid,
} from "../databasefunctions/matches";
import OneColumnLayout from "../layout/OneColLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { Bid } from "../layout/BidDialogCargoOwner";
import { Accept } from "../layout/AcceptDialogCargoOwner";
interface CargoMatchState {
  distance_package: number;
  duration_package: number;
  package_height: number;
  package_length: number;
  package_width: number;
  package_weight: number;
  pick_up_point: string;
  drop_off_point: string;
  earliest_start_time: string;
  latest_start_time: string;
  earliest_end_time: string;
  latest_end_time: string;
  load_types: string[];
  incoterms: { code: string; discription: string };
  tracking_number: string;
  car_reg_number: string;
  offer: string;
  loading_requirements: string[];
  note: string;
  noteTrip: string;
}
/**********************************************************
/ NAME: EvaluateOfferViewCargoOwner()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a view for a cargo owner to evaluate an 
 * offer. It displays information about the cargo and the offer.
 * and lets the cargo owner accept, decline or send a counter offer.
 * 
/********************************************************** 
 * INPUT: 
 * CargoMatchState: An interface that defines all relevant information
 * about a cargo and a match.
 * 

/********************************************************** 
 * FUNCTIONS:
 * sendOffer() - Sends an offer to the carrier
 * declineMatch() - Declines the match
 * acceptMatch() - Accepts the match
 * 
/********************************************************** 
 * COMPONENTS:
 * - useState: A React hook that lets you add state to function components
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing information about the cargo and the offer.
/*********************************************************/
function EvaluateOfferViewCargoOwner() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    offer,
    distance_package,
    duration_package,
    package_height,
    package_length,
    package_width,
    package_weight,
    pick_up_point,
    drop_off_point,
    earliest_start_time,
    latest_start_time,
    earliest_end_time,
    latest_end_time,
    load_types,
    incoterms,
    tracking_number,
    car_reg_number,
    loading_requirements,
    note,
    noteTrip,
  } = state as CargoMatchState;
  const [trackingNumber] = useState(tracking_number);
  const [regNumber] = useState(car_reg_number);
  const [incomingOffer] = useState(offer);
  const [distancePackage] = useState(distance_package);
  const [durationPackage] = useState(duration_package);
  const [packageHeight] = useState(package_height);
  const [packageLength] = useState(package_length);
  const [packageWidth] = useState(package_width);
  const [packageWeight] = useState(package_weight);
  const [pickUpPoint] = useState(pick_up_point);
  const [earliestStartTime] = useState(earliest_start_time);
  const [latestStartTime] = useState(latest_start_time);
  const [earliestEndTime] = useState(earliest_end_time);
  const [latestEndTime] = useState(latest_end_time);
  const [dropOffPoint] = useState(drop_off_point);
  const [loadTypes] = useState(load_types);
  const [loadingRequirements] = useState(loading_requirements);
  const [noteCargoOwner] = useState(note);
  const [noteCarrier] = useState(noteTrip);
  const [incoTerms] = useState(incoterms);
  const [offerValue, setOfferValue] = useState("");

  const sendOffer = async () => {
    await dbGiveOffer(trackingNumber, regNumber, offerValue).then(() => {
      navigate("/cargos");
    });
  };
  const declineMatch = async () => {
    await dbDeclineMatch(trackingNumber, regNumber).then(() => {
      navigate("/cargos");
    });
  };
  const acceptMatch = async () => {
    //await dbAcceptMatch(trackingNumber,regNumber);
    await dbAcceptBid(trackingNumber, regNumber).then(() => {
      navigate("/cargos");
    });
  };
  return (
    <OneColumnLayout
      sectionLabel="Offer Evaluation"
      sectionHeading="Du har fått et tilbud"
      sectionIntro=""
      content={
        <form>
          <Heading> Tilbudet er: {incomingOffer} NOK</Heading>
          <Text>Vil du godta, avslå eller gi et nytt tilbud?</Text>
          <Input
            variant="standardTextInput"
            value={offerValue}
            placeholder="Gi motbud?"
            onChange={(e) => setOfferValue(e.target.value)}
            maxWidth="800px"
          />
          <Grid maxWidth="800px" templateColumns="33% 33% 33%" gap={2}>
            <Button margin="10px" colorScheme={"red"} onClick={declineMatch}>
              Avslå
            </Button>

            <Bid
              onConfirm={() => sendOffer()}
              price={offerValue}
              pickUpPoint={pickUpPoint}
              pickUpTime={earliestStartTime + " - " + latestStartTime}
              incoTerms={incoTerms.code}
              dropOffPoint={dropOffPoint}
              dropOffTime={earliestEndTime + " - " + latestEndTime}
              type={loadTypes}
              size={packageLength + "x" + packageWidth + "x" + packageHeight}
              weight={packageWeight}
              distance={Math.round(distancePackage / 1000)}
              time={Math.round(durationPackage / 60)}
              emissions="0"
              trackingNumber={trackingNumber}
              carRegNumber={car_reg_number}
              loadingRequirement={loadingRequirements}
              noteCargoOwner={noteCargoOwner}
              noteTrip={noteCarrier}
            />
            <Accept
              onConfirm={() => acceptMatch()}
              price={offer}
              pickUpPoint={pickUpPoint}
              pickUpTime={earliestStartTime + " - " + latestStartTime}
              incoTerms={incoTerms.code}
              dropOffPoint={dropOffPoint}
              dropOffTime={earliestEndTime + " - " + latestEndTime}
              type={loadTypes}
              size={packageLength + "x" + packageWidth + "x" + packageHeight}
              weight={packageWeight}
              distance={Math.round(distancePackage / 1000)}
              time={Math.round(durationPackage / 60)}
              emissions="0"
              noteCargoOwner={noteCargoOwner}
              noteTrip={noteCarrier}
              trackingNumber={trackingNumber}
              carRegNumber={car_reg_number}
              loadingRequirement={loadingRequirements}
            />
          </Grid>
        </form>
      }
    />
  );
}

export default EvaluateOfferViewCargoOwner;
