import axios from "axios";
import { findToken } from "../login/Token";
import { BACKEND_URL } from "../../assets/static";
/**********************************************************
/ NAME: findCargoMatch()
/********************************************************** 
 * SUMMARY: 
 * This function fetches the match for a specific cargo from the server using the cargo's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - tracking_number: The tracking number of the cargo
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the match for the cargo.
/*********************************************************/
async function findCargoMatch(tracking_number: string) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/find_match_test?tracking_number=${tracking_number}`;

    return await axios
      .post(urlstring, "", {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: dbGiveOffer()
/********************************************************** 
 * SUMMARY: 
 * This function sends an offer for a specific cargo to the 
 * server using the cargo's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - tracking_number: The tracking number of the cargo
 * - regNumber: The registration number of the carrier
 * - offer: The offer to be sent
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the response from the server.
/*********************************************************/
async function dbGiveOffer(
  tracking_number: string,
  regNumber: string,
  offer: string
) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/send_offer?tracking_number=${tracking_number}&car_reg_number=${regNumber}&offer_kr=${offer}`;

    return await axios
      .get(urlstring, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: dbDeclineMatch()
/********************************************************** 
 * SUMMARY: 
 * This function sends a decline for a specific cargo match 
 * to the server using the cargo's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - tracking_number: The tracking number of the cargo
 * - regNumber: The registration number of the carrier
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the response from the server.
/*********************************************************/
async function dbDeclineMatch(tracking_number: string, regNumber: string) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/decline_offer?tracking_number=${tracking_number}&car_reg_number=${regNumber}`;

    return await axios
      .get(urlstring, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: dbAcceptMatch()
/********************************************************** 
 * SUMMARY: 
 * This function sends an acceptance for a specific cargo 
 * match to the server using the cargo's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - tracking_number: The tracking number of the cargo
 * - regNumber: The registration number of the carrier
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the response from the server.
/*********************************************************/
async function dbAcceptMatch(tracking_number: string, regNumber: string) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/accept_match?tracking_number=${tracking_number}&car_reg_number=${regNumber}`;

    return await axios
      .get(urlstring, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: dbAcceptBid()
/********************************************************** 
 * SUMMARY: 
 * This function sends an acceptance for a specific bid to 
 * the server using the cargo's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - tracking_number: The tracking number of the cargo
 * - regNumber: The registration number of the carrier
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the response from the server.
/*********************************************************/
async function dbAcceptBid(tracking_number: string, regNumber: string) {
  const token: string = findToken();
  try {
    let urlstring;
    urlstring = `${BACKEND_URL}/accepte_bid?tracking_number=${tracking_number}&car_reg_number=${regNumber}`;

    return await axios
      .get(urlstring, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

export {
  findCargoMatch,
  dbGiveOffer,
  dbDeclineMatch,
  dbAcceptMatch,
  dbAcceptBid,
};
